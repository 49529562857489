<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.drivingTemplatesByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="drivingTemplatesHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="drivingTemplates"
            :search="searchString"
            class="elevation-1 cursor-pointer"
            @click:row="editItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.drivingTemplates') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer />

                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closeEditModal"></v-icon>
                    </v-card-title>
                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(saveItem)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider rules="required" v-slot="{ errors }" name="name">
                                  <v-text-field
                                    v-model="editedItem.name"
                                    clearable
                                    :label="$t('driving.templateName')"
                                    type="text"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <validation-provider rules="required" v-slot="{ errors }" name="driving_type">
                                  <v-autocomplete
                                    v-model="editedItem.driving_type_id"
                                    :items="drivingTypes"
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('driving.drivingType')"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"
                                    :error-messages="errors"></v-autocomplete>
                                </validation-provider>
                              </v-col>

                              <v-col cols="12" lg="6" md="6" sm="6">
                                <gmap-autocomplete
                                  :key="googleMapsOptionsKey"
                                  :options="googleMapsOptions"
                                  :setFieldsTo="GoogleApiFields"
                                  :value="editedItem.location_from"
                                  selectFirstOnEnter
                                  @place_changed="selectFromAddress"
                                  class="gmap-autocomplete">
                                  <template v-slot:default="slotProps">
                                    <validation-provider rules="required" v-slot="{ errors }" name="location_from">
                                      <v-text-field
                                        ref="input"
                                        clearable
                                        v-model="editedItem.location_from_name"
                                        :label="$t('driving.locationFrom')"
                                        @focusout="selectFromAddressFocusout"
                                        v-on:attrs="slotProps.attrs"
                                        v-on:listeners="slotProps.listeners"
                                        :error-messages="errors"
                                        @keydown.enter.prevent
                                        >-
                                      </v-text-field>
                                    </validation-provider>
                                  </template>
                                </gmap-autocomplete>
                              </v-col>
                              <v-col v-if="editedItem.driving_type_id === 1" cols="12" lg="6" md="6" sm="6">
                                <gmap-autocomplete
                                  :key="googleMapsOptionsKey"
                                  :options="googleMapsOptions"
                                  :setFieldsTo="GoogleApiFields"
                                  :value="editedItem.location_to"
                                  selectFirstOnEnter
                                  slot-ref-name="input2"
                                  @place_changed="selectToAddress"
                                  class="gmap-autocomplete">
                                  <template v-slot:default="slotProps">
                                    <validation-provider rules="required" v-slot="{ errors }" name="location_to">
                                      <v-text-field
                                        ref="input2"
                                        clearable
                                        v-model="editedItem.location_to_name"
                                        :label="$t('driving.locationTo')"
                                        @focusout="selectToAddressFocusout"
                                        v-on:attrs="slotProps.attrs"
                                        v-on:listeners="slotProps.listeners"
                                        :error-messages="errors"
                                        @keydown.enter.prevent
                                        >-
                                      </v-text-field>
                                    </validation-provider>
                                  </template>
                                </gmap-autocomplete>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <v-autocomplete
                                  v-model="editedItem.vehicle_class_id"
                                  :items="vehicleClasses"
                                  clearable
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('driving.vehicleClass')"
                                  @change="getAllVehiclesByClass()"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </v-col>
                              <v-col v-if="showVehicles" cols="12" lg="6" md="6" sm="6">
                                <v-autocomplete
                                  v-model="editedItem.vehicle_id"
                                  :items="vehicles"
                                  clearable
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('driving.vehicle')"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="6">
                                <v-autocomplete
                                  v-model="editedItem.driver_id"
                                  :items="drivers"
                                  clearable
                                  item-text="profile.full_name"
                                  item-value="id"
                                  :label="$t('driving.driver')"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </v-col>
                              <v-col cols="12" lg="3" md="3" sm="3">
                                <validation-provider
                                  :rules="{ max: 255, regex: /^[A-Z,a-z]{1,3}\d{1,5}$/ }"
                                  v-slot="{ errors }"
                                  name="flight_number"
                                  class="d-flex align-center justify-space-between">
                                  <v-text-field
                                    v-model="editedItem.flight_number"
                                    clearable
                                    :label="$t('driving.flightNumber')"
                                    type="text"
                                    :error-messages="errors"></v-text-field>

                                  <flight-info
                                    :flightNumber="editedItem.flight_number"
                                    :disabled="errors.length > 0 || !editedItem.flight_number" />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="3" md="3" sm="3">
                                <v-checkbox
                                  v-model="editedItem.visible_to_clients"
                                  color="success"
                                  :label="$t('driving.visibleToClients')"></v-checkbox>
                              </v-col>
                              <v-col cols="12" lg="3" md="3" sm="3">
                                <validation-provider
                                  rules="numeric_decimal|min_value:0"
                                  v-slot="{ errors }"
                                  name="price">
                                  <v-text-field
                                    v-model="editedItem.price"
                                    clearable
                                    :label="$t('driving.price')"
                                    type="text"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="6" lg="3" md="3" sm="3">
                                <validation-provider v-slot="{ errors }" name="currency">
                                  <v-autocomplete
                                    v-model="editedItem.currency_id"
                                    :items="currencies"
                                    clearable
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('driving.currency')"
                                    :error-messages="errors"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="6" lg="3" md="3" sm="3">
                                <validation-provider v-slot="{ errors }" name="price_type">
                                  <v-autocomplete
                                    name="price_type"
                                    clearable
                                    item-text="name"
                                    item-value="id"
                                    v-model="editedItem.price_type"
                                    :items="priceTypes"
                                    :label="$t('driving.priceType')"
                                    :error-messages="errors"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="6" lg="3" md="3" sm="3">
                                <validation-provider rules="numeric_decimal|min_value:0" v-slot="{ errors }" name="vat">
                                  <v-text-field
                                    v-model="editedItem.vat_percentage"
                                    clearable
                                    :label="$t('driving.vat') + ' (%)'"
                                    type="text"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer />
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-list-item @click="showDeleteModal(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-dialog v-model="deleteModal" max-width="650">
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            {{ $t('drivingTemplatesPage.deleteDrivingTemplate') }}
            <v-spacer />
            <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteModal"></v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p>
                {{ $t('drivingTemplatesPage.deleteMessage') }}
              </p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn :disabled="!enableDelete" class="primary" text @click="deleteDrivingTemplate">
              {{ $t('buttons.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { drivingTemplatesHeaders } from '@/mixins/data-table-headers';
import { defaultDrivingTemplate } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { getGoogleMapsAPI } from 'gmap-vue';
import FlightInfo from '@/components/drivings/FlightInfo.vue';

export default {
  name: 'DrivingTemplates',
  props: [],
  components: { ButtonSubmit, FlightInfo },
  data: () => ({
    searchString: '',
    dialog: false,
    formTitle: 'drivingTemplatesPage.newItem',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    deletedItem: {},
    editedIndex: -1,
    enableSave: true,
    enableDelete: true,
    deleteModal: false,
    loading: false,
    user: {
      id: '',
      name: '',
      email: '',
      role_id: null,
    },
    google: null,
    googleMapsOptions: {
      strictBounds: false,
    },
    googleMapsOptionsKey: 0,
    GoogleApiFields: ['name', 'formatted_address', 'geometry', 'place_id', 'plus_code', 'url'],
    showVehicles: false,
    priceTypes: [
      { id: 'NET', name: i18n.t('advancedSettings.NET') },
      { id: 'GROSS', name: i18n.t('advancedSettings.GROSS') },
    ],
  }),
  created() {
    this.loadUser();
    this.editedItem = Object.assign({}, defaultDrivingTemplate);
  },
  computed: {
    drivingTemplatesHeaders() {
      return drivingTemplatesHeaders(i18n);
    },
    drivingTemplates() {
      return this.$store.getters['drivingTemplates/getDrivingTemplates'].map((item) => {
        return {
          ...item,
          active: parseInt(item.active), // Convert to integer
          driving_type_id: item.drivingType?.id,
          vehicle_class_id: item.vehicleClass?.id,
          vehicle_id: item.vehicle?.id,
          driver_id: item.driver?.id,
          currency_id: item.currency?.id,
          location_from: JSON.parse(item.location_from),
          location_from_name: JSON.parse(item.location_from)?.name,
          location_to: JSON.parse(item.location_to),
          location_to_name: JSON.parse(item.location_to)?.name,
        };
      });
    },
    drivingTypes() {
      return [
        { id: 1, name: this.$t('drivingOrderType.transfer') },
        { id: 2, name: this.$t('drivingOrderType.dailyRent') },
      ];
    },
    drivers() {
      return this.$store.getters['users/getDrivers']
        .map((item) => {
          return {
            ...item,
            full_name: item.profile?.full_name || 'N/A',
          };
        })
        .filter((item) => {
          return parseInt(item.active) === 1 || item?.id === this.editedItem?.driver_id;
        });
    },

    vehicleClasses() {
      return this.$store.getters['vehicleClasses/getVehicleClasses'].filter((item) => {
        return parseInt(item.active) === 1 || item?.id === this.editedItem?.vehicle_class_id;
      });
    },

    currencies() {
      return this.$store.getters['currencies/getOrganisationCurrencies']
        .map((item) => {
          return {
            ...item.currency,
          };
        })
        .filter((item) => parseInt(item.active) === 1 || item?.id === this.editedItem?.currency_id);
    },
  },
  methods: {
    loadUser() {
      this.user = this.$store.getters['auth/user'];
    },
    async getAllVehiclesByClass() {
      await this.$store
        .dispatch('vehicles/getAllVehiclesByClass', {
          vehicle_class_id: this.editedItem.vehicle_class_id,
        })
        .then((res) => {
          this.vehicles = res.data?.filter((item) => {
            if (parseInt(item.active) === 1 || item?.id === this.editedItem?.vehicle_id) {
              return item;
            }
          });
          let clearOutVehicle = true;
          for (let vehicle of res.data) {
            if (parseInt(vehicle.id) === parseInt(this.editedItem.vehicle_id)) {
              clearOutVehicle = false;
              break;
            }
          }
          if (clearOutVehicle) {
            this.editedItem.vehicle_id = null;
          }
          if (this.editedItem.vehicle_class_id) {
            this.showVehicles = true;
          } else {
            this.showVehicles = false;
          }
        });
    },

    editItem(item) {
      this.editedItem = this.drivingTemplates.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = item.name;
      this.dialog = true;
      this.getAllVehiclesByClass();
    },

    deleteItem(item) {
      this.deletedItem = this.drivingTemplates.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.formTitle = item.name;
      this.dialog = true;
    },

    async saveItem() {
      this.enableSave = false;
      this.loading = true;
      let formData = this.createFormData();

      await this.$store
        .dispatch('drivingTemplates/saveDrivingTemplate', formData)
        .then(() => {
          this.$store.dispatch('drivingTemplates/fetchAllDrivingTemplates');
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeEditModal();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'yellow darken-3' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
          this.formTitle = 'drivingTemplatesPage.newItem';
        });
    },

    async deleteDrivingTemplate() {
      this.enableDelete = false;

      await this.$store
        .dispatch('drivingTemplates/deleteDrivingTemplate', this.editedItemDeleted)
        .then((res) => {
          if (res.success) {
            this.$store.dispatch('drivingTemplates/fetchAllDrivingTemplates');
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeDeleteModal();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.enableDelete = true;
        });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('id', this.editedItem.id ? this.editedItem.id : '');
      formData.append('name', this.editedItem.name ? this.editedItem.name : '');
      formData.append('driving_type_id', this.editedItem.driving_type_id ? this.editedItem.driving_type_id : '');
      formData.append('location_from', JSON.stringify(this.editedItem.location_from));
      this.editedItem.driving_type_id === 1 &&
        formData.append('location_to', JSON.stringify(this.editedItem.location_to));
      formData.append('vehicle_class_id', this.editedItem.vehicle_class_id ? this.editedItem.vehicle_class_id : '');
      formData.append(
        'vehicle_id',
        this.editedItem.vehicle_id && this.editedItem.vehicle_class_id ? this.editedItem.vehicle_id : ''
      );
      formData.append('driver_id', this.editedItem.driver_id ? this.editedItem.driver_id : '');
      formData.append('flight_number', this.editedItem.flight_number ? this.editedItem.flight_number : '');
      formData.append('visible_to_clients', this.editedItem.visible_to_clients ? 1 : 0);
      formData.append('price', this.editedItem.price ? this.editedItem.price : 0);
      formData.append('price_type', this.editedItem.price_type ? this.editedItem.price_type : 'NET');
      formData.append('currency_id', this.editedItem.currency_id ? this.editedItem.currency_id : '');
      formData.append('vat_percentage', this.editedItem.vat_percentage ? this.editedItem.vat_percentage : 0);

      return formData;
    },

    closeEditModal() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultDrivingTemplate);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.formTitle = 'drivingTemplatesPage.newItem';
    },

    showDeleteModal(item) {
      this.editedItemDeleted = item;
      this.deleteModal = true;
    },

    closeDeleteModal() {
      this.deleteModal = false;
    },

    async waitForGoogleMapsAPI() {
      return new Promise((resolve) => {
        const checkExist = setInterval(() => {
          if (window.google && window.google.maps) {
            clearInterval(checkExist);
            resolve();
          }
        }, 100);
      });
    },

    async loadGoogleMaps() {
      try {
        await this.waitForGoogleMapsAPI();
        this.google = await getGoogleMapsAPI();

        if (
          this.user?.organisation?.organisation_settings?.radius_center_lat &&
          this.user?.organisation?.organisation_settings?.radius_center_lng &&
          this.user?.organisation?.organisation_settings?.radius_distance
        ) {
          const distanceKm =
            this.user?.organisation?.organisation_settings.distance_unit === 'mi'
              ? this.user?.organisation?.organisation_settings.radius_distance * 1.60934 // Convert miles to km
              : this.user?.organisation?.organisation_settings.radius_distance; // Keep as km

          const radiusDegrees = distanceKm / 111; // Convert km to degrees

          // Calculate bounding box
          const centerLat = this.user?.organisation?.organisation_settings.radius_center_lat;
          const centerLng = this.user?.organisation?.organisation_settings.radius_center_lng;

          const bounds = new this.google.maps.LatLngBounds(
            new this.google.maps.LatLng(centerLat - radiusDegrees, centerLng - radiusDegrees), // SW Corner
            new this.google.maps.LatLng(centerLat + radiusDegrees, centerLng + radiusDegrees) // NE Corner
          );

          this.googleMapsOptions = {
            ...this.googleMapsOptions,
            bounds: bounds,
            strictBounds: true, // Ensures results are only within bounds
          };

          this.googleMapsOptionsKey++;
        } else {
          this.googleMapsOptions = {
            strictBounds: false, // Ensures results are only within bounds
          };
          this.googleMapsOptionsKey++;
        }
      } catch (error) {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    selectFromAddress(input) {
      this.editedItem.location_from = input;
      this.editedItem.location_from_name = input.name + ` (${input.formatted_address})`;
    },
    selectToAddress(input2) {
      this.editedItem.location_to = input2;
      this.editedItem.location_to_name = input2.name + ` (${input2.formatted_address})`;
    },
    selectFromAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedItem.location_from?.name &&
        value !== this.editedItem.location_from?.name + ` (${this.editedItem.location_from?.formatted_address})`
      ) {
        this.editedItem.location_from = { name: value };
        this.editedItem.location_from_name = value;
      }
    },
    selectToAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedItem.location_to?.name &&
        value !== this.editedItem.location_to?.name + ` (${this.editedItem.location_to?.formatted_address})`
      ) {
        this.editedItem.location_to = { name: value };
        this.editedItem.location_to_name = value;
      }
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.closeEditModal();
      }
    },
    user: {
      deep: true,
      handler() {
        this.loadGoogleMaps();
      },
    },
  },
};
</script>

<style scoped>
.line {
  color: rgba(0, 0, 0, 0.42);
}
</style>
